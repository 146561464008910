import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import FeatherIcons from '@expo/vector-icons/Feather';
import { font, paddingLeft, paddingRight, theme } from '@src/styles';
import { useScreen } from '@src/hooks/useScreen';

export const Stepper = ({ labels, stepperData }) => {
    const { isLandscape, hp } = useScreen();

    const stepSize = isLandscape ? hp('8%') : 40;
    const stepFontSize = isLandscape ? stepSize / 2 : 16;
    const stepIconSize = isLandscape ? stepSize / 2 : 20;

    const styles = StyleSheet.create({
        container: {
            marginBottom: 44,
            paddingLeft,
            paddingRight,
            ...(isLandscape && {
                marginBottom: hp('5%'),
            }),
        },
        fullFilled: {
            backgroundColor: theme.palette.primary.deepBlue,
        },
        hr: {
            backgroundColor: theme.palette.primary.deepBlue,
            height: isLandscape ? hp('0.3%') : 1,
            position: 'absolute',
            top: stepSize / 2,
            transform: [{ translateY: -0.5 }],
            width: '100%',
        },
        step: {
            alignItems: 'center',
            backgroundColor: theme.palette.common.white,
            borderColor: theme.palette.primary.deepBlue,
            borderRadius: stepSize / 2,
            borderWidth: 1,
            display: 'flex',
            height: stepSize,
            justifyContent: 'center',
            marginBottom: 8,
            width: stepSize,
        },
        stepLabelText: {
            fontSize: 11,
            textAlign: 'center',
            ...(isLandscape && {
                fontSize: hp('2.1%'),
                lineHeight: hp('2.5%'),
            }),
        },
        stepText: {
            ...font.normal,
            color: isLandscape ? theme.palette.primary.deepBlue : theme.palette.primary.darkBlue,
            fontSize: stepFontSize,
            letterSpacing: 0.15,
            lineHeight: stepFontSize,
        },
        stepTextFullFilled: {
            color: theme.palette.common.white,
        },
        stepWrapper: {
            alignItems: 'center',
            display: 'flex',
        },
        stepWrapperLabel: {
            width: stepSize * 2,
        },
        stepper: {
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        wrapper: {
            marginLeft: stepSize / 2,
            marginRight: stepSize / 2,
            position: 'relative',
        },
        wrapperMarginBottom: {
            marginBottom: 8,
        },
    });

    return (
        <View style={styles.container}>
            <View style={[styles.wrapper, styles.wrapperMarginBottom]}>
                <View style={styles.hr} />
                <View style={styles.stepper}>
                    {labels.map((label, index) => {
                        return (
                            <View
                                key={label}
                                style={styles.stepWrapper}
                            >
                                <View
                                    style={[
                                        styles.step,
                                        index + 1 <= stepperData.curStep ? styles.fullFilled : {},
                                    ]}
                                >
                                    {index + 1 < stepperData.curStep ? (
                                        <FeatherIcons
                                            name="check"
                                            size={stepIconSize}
                                            color="#fff"
                                        />
                                    ) : (
                                        <Text
                                            style={[
                                                styles.stepText,
                                                index + 1 === stepperData.curStep && styles.stepTextFullFilled,
                                            ]}
                                        >
                                            {index + 1}
                                        </Text>
                                    )}
                                </View>
                            </View>
                        );
                    })}
                </View>
            </View>
            <View>
                <View style={styles.stepper}>
                    {labels.map((label) => {
                        return (
                            <View
                                key={label}
                                style={[styles.stepWrapper, styles.stepWrapperLabel]}
                            >
                                <Text style={styles.stepLabelText}>
                                    {label}
                                </Text>
                            </View>
                        );
                    })}
                </View>
            </View>
        </View>
    );
};
