import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { font, theme } from '@src/styles';
import { useScreen } from '@src/hooks/useScreen';

export const RadioButton = ({ label, id, value, setValue }) => {
    const { isLandscape, hp } = useScreen();

    const onPress = () => {
        setValue(id);
    };

    const styles = StyleSheet.create({
        inner: {
            backgroundColor: theme.palette.primary.deepBlue,
            borderRadius: 7,
            height: 14,
            width: 14,
            ...(isLandscape && {
                height: hp('1.6%'),
                width: hp('1.6%'),
                borderRadius: hp('1.6%') / 2,
            }),
        },
        label: {
            ...font.normal,
            fontSize: 24,
            letterSpacing: 0.18,
            lineHeight: 24,
            marginTop: 10,
            ...(isLandscape && {
                fontSize: hp('2.4%'),
                letterSpacing: 0.24,
                lineHeight: hp('2.4%'),
            }),
        },
        outer: {
            alignItems: 'center',
            borderColor: theme.palette.primary.deepBlue,
            borderRadius: 12,
            borderWidth: 1,
            display: 'flex',
            height: 24,
            justifyContent: 'center',
            width: 24,
            ...(isLandscape && {
                height: hp('3%'),
                width: hp('3%'),
                borderRadius: hp('3%') / 2,
            }),
        },
        wrapper: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
        },
    });

    return (
        <TouchableOpacity
            style={styles.wrapper}
            onPress={onPress}
        >
            <View style={styles.outer}>
                {id === value && <View style={styles.inner} />}
            </View>
            <Text style={styles.label}>{label}</Text>
        </TouchableOpacity>
    );
};
