import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { AddLoggerContext } from '@src/AddLogger/AddLoggerContext';
import { useTranslation } from 'react-i18next';
import { Input } from '@src/components/shared-components/Input';
import { RadioButton } from '@src/components/shared-components/RadioButton';
import { ASSET_TYPE, INSTANCE_TYPES } from '@src/constants';
import { useBackendEndpoints } from '@src/hooks/useBackendEndpoints';
import { STATUSES, Status } from '@src/components/shared-components/Status';
import { Layout } from '@src/components/Layout';
import { Container } from '@src/components/Layout/Container';
import { Title } from '@src/components/Title';
import { Number } from '@src/components/Number';
import { useNotification } from '@src/hooks/useNotification';
import { useScreen } from '@src/hooks/useScreen';
import { useNavigationNoHistory } from '@src/hooks/useNavigationNoHistory';
import { ADD_LOGGER_SCREENS } from '../constant';
import { getEnterCodeScreenStyles } from '../styles';
import { fixError } from './lib';

export const AddNewAsset = () => {
    const { t } = useTranslation();
    const navigationNoHistory = useNavigationNoHistory();
    const { showSuccess } = useNotification();
    const { isLandscape, hp } = useScreen();
    const { asset, setAsset, stepper } = useContext(AddLoggerContext);
    const { checkPairingByAsset, addAsset } = useBackendEndpoints().requests;
    const [code, setCode] = useState(asset?.number || '');
    const [radioType, setRadioType] = useState(ASSET_TYPE.PALLET);
    const [status, setStatus] = useState(null);
    const [error, setError] = useState('');

    const onConfirm = async () => {
        // check if asset already exists
        const checkResult = await checkPairingByAsset(code);

        if (checkResult?.status === 200 && checkResult?.data?.assetNumber) {
            // asset already exists
            setAsset(curAsset => ({ ...curAsset, number: code }));
            setStatus(STATUSES.ASSET_EXISTS);
            return;
        }

        // asset doesn't exist
        // add asset
        // TODO: refactor this, it
        // has duplicate code with addAssetHandler src/AddLogger/Screens/CheckAssetOrLogger/CheckAssetOrLogger.tsx
        const result = await addAsset(code, radioType);

        if (result?.status === 201) {
            // asset added but we need to get its id
            const assetWithId = await checkPairingByAsset(code);

            if (assetWithId?.status === 200 && assetWithId?.data) {
                const { data } = assetWithId;

                if (data.assetTypeCode) {
                    data.assetType = data.assetTypeCode; // TODO: delete this after backend assetTypeCode -> assetType
                }

                setAsset(curAsset => ({ ...curAsset, ...data, number: code }));
                stepper.nextStep();
                navigationNoHistory({ name: ADD_LOGGER_SCREENS.SCAN_LOGGER_CODE });
                showSuccess(t('ADD_LOGGER.ASSET_ADDED', { assetNumber: code }));
            } else {
                navigationNoHistory({ name: ADD_LOGGER_SCREENS.ERROR });
            }
        } else if (result?.status === 400) {
            // TODO: fix any
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (result?.message) {
                // TODO: fix any
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const errorMessage = fixError(result?.message);

                setError(errorMessage);
            }
        } else {
            navigationNoHistory({ name: ADD_LOGGER_SCREENS.ERROR });
        }
    };

    const onScanCode = () => {
        navigationNoHistory({ name: ADD_LOGGER_SCREENS.ENTER_ASSET_CODE });
    };

    useEffect(() => {
        setError('');
    }, [code]);

    const onChangeText = (value) => setCode(value);

    const sharedStyles = getEnterCodeScreenStyles({ hp });

    const styles = StyleSheet.create({
        customContainerWidth: {
            ...(isLandscape && {
                width: '44%',
            }),
        },
        radioBtnsWrapper: {
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
        },
    });

    if (status === STATUSES.ASSET_EXISTS) {
        return (
            <Layout verticalAlign="center">
                <Container isPaddingTop>
                    <Status
                        status={STATUSES.ASSET_EXISTS}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore TODO: fix type
                        instanceType={INSTANCE_TYPES.ASSET}
                    />
                    <Number>{code}</Number>
                </Container>

                <Container>
                    <ButtonsBlock
                        primaryBtnText={t('COMMON.EDIT')}
                        primaryBtnOnPress={() => setStatus(null)}
                        secondaryBtnText={t('COMMON.CANCEL')}
                        secondaryBtnOnPress={() => navigationNoHistory({ name: ADD_LOGGER_SCREENS.ENTER_ASSET_CODE })}
                    />
                </Container>
            </Layout>
        );
    }

    return (
        <Layout verticalAlign="center">
            <Container style={styles.customContainerWidth}>
                <Title>
                    {t('ADD_LOGGER.ENTER_ASSET_CODE')}
                </Title>
                <View style={sharedStyles.inputWrapper}>
                    <Input
                        value={code}
                        onChangeText={onChangeText}
                        placeholder="XXX 9999(9) XX"
                        placeholderTextColor="#D7D7D7"
                        error={error}
                    />
                </View>
                <View style={styles.radioBtnsWrapper}>
                    <RadioButton
                        label={t('COMMON.PALLET')}
                        id={ASSET_TYPE.PALLET}
                        value={radioType}
                        setValue={setRadioType}
                    />
                    <RadioButton
                        label={t('COMMON.CONTAINER')}
                        id={ASSET_TYPE.CONTAINER}
                        value={radioType}
                        setValue={setRadioType}
                    />
                </View>
            </Container>

            <Container style={styles.customContainerWidth}>
                <ButtonsBlock
                    primaryBtnText={t('COMMON.CONFIRM')}
                    primaryBtnOnPress={onConfirm}
                    secondaryBtnText={t('COMMON.BACK')}
                    secondaryBtnOnPress={onScanCode}
                />
            </Container>
        </Layout>
    );
};
