import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { ASSET_TYPE } from '@src/constants';
import { useTranslation } from 'react-i18next';
import { font, theme } from '@src/styles';
import { useScreen } from '@src/hooks/useScreen';

type PropsPair = {
  style?: object,
  assetNumber?: number,
  loggerNumber?: number,
  assetType? : string,
  isSuccess? : boolean,
  deviderTextCode? : string,
};

export const Pair = ({
    style = {},
    loggerNumber,
    assetNumber,
    assetType,
    deviderTextCode,
    isSuccess = false,
}: PropsPair) => {
    const { t } = useTranslation();
    const { isLandscape, wp, hp } = useScreen();

    const styles = StyleSheet.create({
        column: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        devider: {
            marginBottom: 16,
            marginTop: 16,
            paddingLeft: 9,
            ...(isLandscape && {
                marginBottom: hp('2.5%'),
                marginTop: hp('2.5%'),
            }),
        },
        deviderText: {
            ...font.medium,
            color: theme.palette.secondary.main,
            fontSize: 14,
            letterSpacing: 0.1,
            lineHeight: 24,
            ...(isLandscape && {
                ...font.normal,
                fontSize: hp('2.3%'),
                letterSpacing: 0.15,
            }),
        },
        deviderTextSuccess: {
            color: theme.palette.common.white,
        },
        left: {
            marginRight: 20,
            paddingBottom: 8,
            paddingTop: 10,
            ...(isLandscape && {
                paddingBottom: hp('2.1%'),
                paddingTop: hp('2.1%'),
                marginRight: wp('1.4%'),
            }),
        },
        leftText: {
            ...font.medium,
            fontSize: 14,
            letterSpacing: 0.1,
            lineHeight: 14,
            textAlign: 'right',
            ...(isLandscape && {
                ...font.normal,
                fontSize: hp('2.3%'),
                lineHeight: hp('2.3%'),
                letterSpacing: 0.15,
            }),
        },
        leftTextSuccess: {
            color: theme.palette.common.white,
        },
        number: {
            backgroundColor: theme.palette.secondary[100],
            borderRadius: 10,
            paddingBottom: 8,
            paddingLeft: 21,
            paddingRight: 21,
            paddingTop: 8,
            ...(isLandscape && {
                paddingBottom: hp('2%'),
                paddingTop: hp('2.2%'),
                paddingLeft: wp('1.5%'),
                paddingRight: wp('1.5%'),
                borderRadius: hp('1.6%'),
            }),
        },
        right: {
            flexGrow: 1,
        },
        rightNumberText: {
            ...font.normal,
            color: theme.palette.primary.darkBlue,
            fontSize: 16,
            letterSpacing: 0.15,
            lineHeight: 18,
            textTransform: 'uppercase',
            ...(isLandscape && {
                ...font.medium,
                fontSize: hp('3%'),
                lineHeight: hp('3%'),
            }),
        },
        rightNumberTextSuccess: {
            color: theme.palette.common.black,
        },
        rightSuccess: {
            backgroundColor: theme.palette.common.white,
        },
        wrapper: {
            alignItems: 'stretch',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginBottom: 10,
            width: '100%',
        },
    });

    const getAssetTypeText = () => {
        if (assetType === ASSET_TYPE.PALLET) {
            return t('COMMON.PALLET');
        }

        if (assetType === ASSET_TYPE.CONTAINER) {
            return t('COMMON.CONTAINER');
        }

        return t('COMMON.ASSET');
    };

    return (
        <View style={style}>
            <View style={styles.wrapper}>

                <View style={[styles.column, styles.left]}>
                    <View>
                        <Text style={[styles.leftText, isSuccess ? styles.leftTextSuccess : {}]}>
                            {getAssetTypeText()}
                        </Text>
                    </View>

                    <View>
                        <Text style={[styles.leftText, isSuccess ? styles.leftTextSuccess : {}]}>
                            {t('COMMON.LOGGER')}
                        </Text>
                    </View>
                </View>

                <View style={[styles.column, styles.right]}>
                    <View style={[styles.number, isSuccess ? styles.rightSuccess : {}]}>
                        <Text style={[styles.rightNumberText, isSuccess ? styles.rightNumberTextSuccess : {}]}>
                            {assetNumber}
                        </Text>
                    </View>

                    <View style={styles.devider}>
                        <Text style={[styles.deviderText, isSuccess ? styles.deviderTextSuccess : {}]}>
                            {t(deviderTextCode || 'ADD_LOGGER.PAIRED_WITH')}
                        </Text>
                    </View>

                    <View style={[styles.number, isSuccess ? styles.rightSuccess : {}]}>
                        <Text style={[styles.rightNumberText, isSuccess ? styles.rightNumberTextSuccess : {}]}>
                            {loggerNumber}
                        </Text>
                    </View>
                </View>

            </View>
        </View>
    );
};
