import axios, { Method } from 'axios';
import { useJWTToken } from '@src/hooks/useJWTToken';
import { useContext, useMemo } from 'react';
import { apiUrl } from '@src/constants/env';
import { StoreContext } from '@src/Contexts/StoreContext';
import {
    catchErrors,
    defaultGetAllQuery,
    getConfig,
    getQueries,
    Query,
} from './lib';
import { useNotification } from '../useNotification';

/**
 * hook to use the Secure Backend.
 * Basic CRUD methods, FlexibleRequest for more specific requests
 * @param objectName name of required object in Secure BE (shipment, user, company etc.)
 * @returns a group of methods: without (requests)
 */
export const useBackendEndpoints = (objectName: string = '') => {
    const token = useJWTToken();
    const config = useMemo(() => getConfig(token, {}), [token]);
    const { auth } = useContext(StoreContext);
    const { logout = () => {} } = auth;
    const { showError } = useNotification();

    if (!token) {
        return { error: 'No token' };
    }

    const instance = axios.create({
        baseURL: `${apiUrl}/${objectName.toLowerCase()}`,
    });

    const requests = {
        Get: (id: string | number, queryObj: object = null) => {
            return instance
                .get(`/${id}${getQueries(queryObj)}`, config)
                .catch((error) => catchErrors({ error, logout, showNotification: showError }));
        },
        GetAll: (queryObj: Query = defaultGetAllQuery) => {
            return instance.get(getQueries(queryObj), config).catch((error) => catchErrors({
                error,
                logout,
                showNotification: showError,
            }));
        },
        Create: (params: object = {}, queryObj: object = null) => {
            return instance.post(getQueries(queryObj), params, config).catch((error) => catchErrors({
                error,
                logout,
                showNotification: showError,
            }));
        },
        Update: (id: string | number, params: object = {}, queryObj: object = null, headers = {}) => {
            const customConfig = getConfig(token, headers);

            return instance
                .put(`/${id}${getQueries(queryObj)}`, params, customConfig)
                .catch((error) => catchErrors({ error, logout, showNotification: showError }));
        },
        PutBody: (data: number | object, queryObj: object = null, headers = {}) => {
            const customConfig = getConfig(token, headers);
            const isNumeric = typeof data === 'number';

            return instance
                .put(`/${getQueries(queryObj)}`, isNumeric ? data?.toString() : data, customConfig)
                .catch((error) => catchErrors({ error, logout, showNotification: showError }));
        },
        Delete: (id: string | number, queryObj: object = null) => {
            return instance
                .delete(`/${id}${getQueries(queryObj)}`, config)
                .catch((error) => catchErrors({ error, logout, showNotification: showError }));
        },
        Patch: (id: string | number, data: object = {}, queryObj: object = null) => {
            return instance
                .patch(`/${id}${getQueries(queryObj)}`, data, config)
                .catch((error) => catchErrors({ error, logout, showNotification: showError }));
        },
        FlexibleRequest: (
            type: Method,
            url: string = '',
            data = null,
            headers: object = {},
            additionalConfig: object = {},
        ) => {
            const customConfig = getConfig(token, headers);

            return instance({
                method: type,
                url,
                data,
                ...customConfig,
                ...additionalConfig,
            }).catch((error) => catchErrors({ error, logout, showNotification: showError }));
        },
        checkPairingByAsset: (assetNumber: string | number) => {
            return instance({
                method: 'GET',
                url: `${apiUrl}/assets/pairing-by-asset?assetNumber=${assetNumber}`,
                ...config,
            }).catch((error) => catchErrors({ error, logout, showNotification: showError }));
        },
        checkPairingByLogger: (loggerNumber: string | number) => {
            return instance({
                method: 'GET',
                url: `${apiUrl}/assets/pairing-by-logger?loggerNumber=${loggerNumber}`,
                ...config,
            }).catch((error) => catchErrors({ error, logout, showNotification: showError }));
        },
        loggerPairing: (
            assetId: string | number,
            loggerNumber: string | number,
            pairingPhotoFileNames: string[],
        ) => {
            return instance({
                method: 'POST',
                url: `${apiUrl}/assets/${assetId}/action/add-logger`,
                data: {
                    loggerNumber,
                    pairingPhotoFileNames,
                },
                ...config,
            }).catch((error) => catchErrors({ error, logout, showNotification: showError }));
        },
        addAsset: (assetNumber: string | number, assetType: string) => {
            return instance({
                method: 'POST',
                url: `${apiUrl}/assets`,
                data: {
                    assetNumber,
                    assetType,
                    assetTypeCode: assetType, // TODO: remove this line after BE assetTypeCode -> assetType
                },
                ...config,
            }).catch((error) => catchErrors({
                error, logout, showNotification: showError, silentErrorStatuses: [400],
            }));
        },
    };

    return {
        requests,
    };
};
